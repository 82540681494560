import axios from 'axios';

export const getRequest = async (endpoint: string) => {
    const response = await axios.get(
        endpoint,
        { baseURL: process.env.REACT_APP_API_ENDPOINT }
    );

    return response.data;
};
