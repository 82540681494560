import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSongSearch, getSong } from '../store/actions';
import { songSearch, song } from '../store/reducers';
import Template from '../components/_Template';
import ProgressBar from '../components/presentation/ProgressBar';
import SearchForm from '../components/SearchForm';
import SearchResultList from '../components/SearchResultList';
import SearchItem from '../components/SearchItem';
import styles from './SearchPage.module.scss';

const SearchPage = (): React.ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const songResult = useSelector((state: any) => state.song);
    const searchResults = useSelector((state: any) => state.songSearch);
    const searchQueryParameter = params.query;
    const songIdParameter = params.id;

    const renderSearchList =
        searchQueryParameter &&
        !searchResults.error &&
        searchResults.results.length > 0 &&
        searchResults.request_status === 'resolved';
    const renderSearchItem =
        songIdParameter &&
        !songResult.error &&
        songResult.request_status === 'resolved';
    const renderProgressBar =
        songResult.request_status === 'pending' ||
        searchResults.request_status === 'pending';
    const renderError =
        (
            songResult.request_status === 'resolved' &&
            songResult.error
        ) ||
        (
            searchResults.request_status === 'resolved' && 
            searchResults.error
        );

    //get data on page load if parameter
    React.useEffect(() => {
        //get song data if song id parameter
        if (songIdParameter) {
            dispatch(getSong({ query: songIdParameter }));
        }
        //get song search list data if search query parameter
        else if (searchQueryParameter && searchResults.results.length === 0) {
            dispatch(getSongSearch({ query: searchQueryParameter }));
        }
    }, [dispatch, searchQueryParameter, songIdParameter, searchResults.results]);

    const searchSubmitHandler = (query: string) => {
        navigate(`/search/${query}`);
        dispatch(songSearch.actions.clearSongs());
        dispatch(song.actions.clearSong());
        dispatch(getSongSearch({ query }));
    };

    return (
        <Template>
            <div className={styles.root}>
                <div className={styles.wrapper}>
                    <div className={styles.searchForm}>
                        <SearchForm
                            searchSubmitHandler={searchSubmitHandler}
                            searchField={searchQueryParameter}
                        />
                    </div>
                    {renderProgressBar && (
                        <div className={styles.progressIndicator}>
                            <ProgressBar color="#adb5bd" />
                        </div>
                    )}
                    {renderSearchList && (
                        <div className={styles.searchList}>
                            <SearchResultList searchResults={searchResults} />
                        </div>
                    )}
                    {renderSearchItem && (
                        <div className={styles.searchItem}>
                            <SearchItem item={songResult} />
                        </div>
                    )}
                    {renderError && (
                        <div className={styles.error}>
                            Sorry, nothing found.
                        </div>
                    )}
                </div>
            </div>
        </Template>
    );
};

export default SearchPage;
