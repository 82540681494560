import React from 'react';
import Template from '../components/_Template';
import styles from './AboutUs.module.scss';

const AboutUs = (): React.ReactElement => {
    return (
        <Template>
            <div className={styles.root}>
                <div className={styles.wrapper}>
                    <h2>About Us</h2>
                </div>
            </div>
        </Template>
    );
};

export default AboutUs;
