import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SearchPage from './SearchPage';
import AboutUs from './AboutUs';
import ErrorPage from './ErrorPage';

export const Router = () => (
    <Routes>
        <Route index element={<SearchPage />} />
        <Route path={'/about-us'} element={<AboutUs />} />
        <Route path={'/search/:query'} element={<SearchPage />} />
        <Route path={'/search'} element={<SearchPage />} />
        <Route path={'/songs/:id'} element={<SearchPage />} />
        <Route path="*" element={<ErrorPage />} />
    </Routes>
);

export default Router;
