import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { BreakpointProvider } from './components/presentation/BreakpointProvider';
import Router from './routes';

function App() {
    return (
        <BrowserRouter>
            <BreakpointProvider>
                <div className="app">
                    <Router />
                </div>
            </BreakpointProvider>
        </BrowserRouter>
    );
}

export default App;
