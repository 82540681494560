import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styles from './_Template.module.scss';

const Template = ({
    children,
}: TemplateProps): React.ReactElement => {
    return (
        <div className={styles.root}>
            <div>
                <Header />
                <main>{children}</main>
            </div>
            <Footer />
        </div>
    );
};

type TemplateProps = {
    children: React.ReactElement;
};

export default Template;
