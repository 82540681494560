import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/presentation/Button';
import styles from './ErrorPage.module.scss';

const ErrorPage = (): React.ReactElement => {
    const navigate = useNavigate();

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <h1>404</h1>
                <Button
                    background="gray"
                    color="white"
                    onClick={() => {
                        navigate('/', { replace: true });
                    }}
                >
                    Go Back
                </Button>
            </div>
        </div>
    );
};

export default ErrorPage;
